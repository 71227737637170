import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBw_uIwyBiA2IGhbSi57OK306-KUEfk1bc",
  authDomain: "motkraft-prod.firebaseapp.com",
  projectId: "motkraft-prod",
  storageBucket: "motkraft-prod.appspot.com",
  messagingSenderId: "678747922007",
  appId: "1:678747922007:web:fe116c49df6ea7a8390d12",
  measurementId: "G-ELHWJTSJ06"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
