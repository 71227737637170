import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';

// Contexts
import { AppContext } from 'App';

// Services
import VippsService from 'services/vippsService.js';
import oAuthService from 'services/oAuthService';
import AnalyticsService, {
  analyticEventTypes,
} from 'services/analyticsService';

const Vipps = () => {
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');

  const { setApp } = useContext(AppContext);

  useEffect(() => {
    setApp({ isLoading: true });
  }, []);

  useEffect(() => {
    // TODO: Fix bug where component instantly mounts twice
    const fetchToken = setTimeout(async () => {
      const vippsToken = await VippsService.getToken(code, state);
      const userInfo = await VippsService.getUser(vippsToken.data);

      const clientId = sessionStorage.getItem('client_id');
      const redirectUri = sessionStorage.getItem('redirect_uri');
      const codeChallenge = sessionStorage.getItem('code_challenge');
      const codeChallengeMethod = sessionStorage.getItem(
        'code_challenge_method'
      );

      const codeUri = await oAuthService
        .authorize(
          {
            ...userInfo,
            anonymousId: sessionStorage.getItem('anonymous_id') || '',
          },
          clientId,
          redirectUri,
          state,
          codeChallenge || '',
          codeChallengeMethod || ''
        )
        .catch(console.error);

      if (codeUri) {
        setApp({ isLoading: true });
        AnalyticsService.sendEvent(analyticEventTypes.LOGIN_SUCCESS, {
          method: 'VIPPS',
        }).finally(() => {
          window.location.href =
            redirectUri + codeUri.data.replace('/oauth/login', '');
        });
      }
    }, 100);
    return () => clearTimeout(fetchToken);
  }, [code]);

  return <div />;
};

export default Vipps;
