import React, { useEffect, useContext } from 'react';
import styles from '../login.module.css';

// Contexts
import { AppContext } from 'App';

// Services
import VippsService from 'services/vippsService';

// Assets
import VippsLogin from 'assets/images/vipps_login.svg';

const VippsButton = ({ redirectImmediately }) => {
  const { app, setApp } = useContext(AppContext);

  useEffect(() => {
    if (!app.isLoading) {
      if (redirectImmediately) {
        vippsLogin();
      }
    }
  }, [app, redirectImmediately]);

  const vippsLogin = async () => {
    const state = sessionStorage.getItem('state');
    const uri = await VippsService.getRedirectURI(state);
    if (uri) {
      window.location.href = uri.data;
    } else {
      console.error('Vipps redirect uri was not found');
    }
  };

  return (
    <a className={styles.vippsButton} onClick={vippsLogin}>
      <img src={VippsLogin} />
    </a>
  );
};

export default VippsButton;
