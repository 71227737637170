import React, { useEffect, useState, useContext } from 'react';
import styles from './login.module.css';
import { AppContext } from 'App';

// Services
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from 'services/firebaseService';
import oAuthService from 'services/oAuthService';

// Components
import VippsButton from './components/VippsButton';
import { useLocation } from 'react-router-dom';
import Button from 'components/base/Button/Button';
import CountryPicker from 'components/misc/CountryPicker/CountryPicker';

// Assets
import MotkraftLogo from 'assets/images/motkraft_logo.png';
import AnalyticsService, {
  analyticEventTypes,
} from 'services/analyticsService';

const Login = () => {
  const params = new URLSearchParams(useLocation().search);

  let clientId = params.get('client_id');
  let redirectUri = params.get('redirect_uri');
  let state = params.get('state');
  let vippsRedirect = params.get('vipps_redirect');
  let codeChallenge = params.get('code_challenge');
  let codeChallengeMethod = params.get('code_challenge_method');

  if (!clientId) clientId = sessionStorage.getItem('client_id');
  if (!redirectUri) redirectUri = sessionStorage.getItem('redirect_uri');

  const { setApp } = useContext(AppContext);

  const [mounted, setMounted] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [inputErrorMessage, setInputErrorMessage] = useState(null);
  const [smsInput, setSMSInput] = useState('');
  const [usingPhoneNumber, setUsingPhoneNumber] = useState(null); // Used when resending the sms authentication multiple times
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [secondsBeforeResendSMS, setSecondsBeforeResendSMS] = useState(null);
  const [secondsBeforeResendSMSTimer, setSecondsBeforeResendSMSTimer] =
    useState(null);

  useEffect(() => {
    // There always needs to be a state because Vipps requires it.
    if (!state || state.length < 8) {
      state = 'emptyState';
    }
    sessionStorage.setItem('state', state);

    if (clientId) sessionStorage.setItem('client_id', clientId);
    if (redirectUri) sessionStorage.setItem('redirect_uri', redirectUri);

    // PKCE
    if (codeChallenge) {
      sessionStorage.setItem('code_challenge', codeChallenge);
    } else {
      sessionStorage.removeItem('code_challenge');
    }

    if (codeChallengeMethod) {
      sessionStorage.setItem('code_challenge_method', codeChallengeMethod);
    } else {
      sessionStorage.removeItem('code_challenge_method');
    }
  }, [clientId, redirectUri, state, codeChallenge, codeChallengeMethod]);

  useEffect(() => {
    if (auth) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        auth
      );
    }
  }, [auth]);

  const startProgressBar = () => {
    let timeLeft = 30;
    setSecondsBeforeResendSMS(timeLeft);
    setSecondsBeforeResendSMSTimer(
      setInterval(() => {
        setSecondsBeforeResendSMS(timeLeft);
        timeLeft--;
      }, 1000)
    );
  };

  const sendSMS = () => {
    setApp({ isLoading: true });
    setInputErrorMessage(null);

    // Reset timer
    if (secondsBeforeResendSMSTimer) clearInterval(secondsBeforeResendSMSTimer);
    setSecondsBeforeResendSMSTimer(null);
    setSecondsBeforeResendSMS(null);

    let phoneNumber = usingPhoneNumber || smsInput;
    const countryCallingCode = selectedCountry.countryCallingCode;

    // We only input check norwegian numbers for now
    if (
      countryCallingCode === '+47' &&
      (phoneNumber.length !== 8 ||
        !['4', '9'].includes(phoneNumber.slice(0, 1)))
    ) {
      setApp({ isLoading: false });
      AnalyticsService.sendEvent(analyticEventTypes.ERROR, {
        error: 'INVALID_NUMBER',
        number: phoneNumber || '',
      });
      return setInputErrorMessage('Ugyldig mobilnummer');
    }

    phoneNumber = countryCallingCode + phoneNumber;

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((result) => {
        setUsingPhoneNumber(phoneNumber.replace(countryCallingCode, ''));
        setSMSInput('');
        setConfirmationResult(result);
        startProgressBar();
      })
      .catch((error) => {
        setUsingPhoneNumber(null);
        setConfirmationResult(null);
        setSecondsBeforeResendSMS(null);
        if (error.code === 'auth/too-many-requests') {
          setInputErrorMessage(
            'For mange forsøk. Prøv igjen om noen minutter.'
          );
        } else {
          setInputErrorMessage('Noe gikk galt. Vennligst prøv igjen senere');
        }
      })
      .finally(() => {
        setApp({ isLoading: false });
      });
  };

  const confirmSMS = async () => {
    setApp({ isLoading: true });
    setInputErrorMessage(null);

    const code = smsInput;
    if (code === '') {
      setInputErrorMessage('Kode kreves');
      setApp({ isLoading: false });
    }

    let codeUri = null;
    await confirmationResult
      .confirm(code)
      .then(async (result) => {
        codeUri = await oAuthService
          .authorize(
            {
              anonymousId: sessionStorage.getItem('anonymous_id') || '',
              phoneNumber: result.user.phoneNumber,
            },
            clientId,
            redirectUri,
            state,
            codeChallenge,
            codeChallengeMethod
          )
          .catch(async (error) => {
            return await AnalyticsService.sendEvent(
              analyticEventTypes.ERROR,
              error || ''
            );
          });
      })
      .catch((error) => {
        AnalyticsService.sendEvent(analyticEventTypes.ERROR, {
          error: error.code || '',
        });

        if (error.code === 'auth/invalid-verification-code') {
          setInputErrorMessage('Feil kode. Sjekk at du tastet riktig.');
        }
        if (error.code === 'auth/code-expired') {
          setInputErrorMessage('Koden har utløpt. Prøv igjen.');
          setSMSInput('');
          return setConfirmationResult(null);
        }
      })
      .finally(() => {
        setApp({ isLoading: false });
      });

    if (codeUri) {
      setApp({ isLoading: true });
      window.location.href =
        redirectUri + codeUri.data.replace('/oauth/login', '');
    }
  };

  const resetSMSAuth = () => {
    if (secondsBeforeResendSMSTimer) clearInterval(secondsBeforeResendSMSTimer);
    setSecondsBeforeResendSMSTimer(null);
    setSecondsBeforeResendSMS(null);
    setConfirmationResult(null);
    setSMSInput(usingPhoneNumber);
    setUsingPhoneNumber(null);
  };

  return (
    <div className={styles.login}>
      <div className={'center'}>
        <img src={MotkraftLogo} className={styles.logo} />
        <VippsButton redirectImmediately={vippsRedirect} />
        <p className={styles.optionDivider}>Eller</p>
        {!usingPhoneNumber && (
          <label
            htmlFor={'phone-number-input'}
            className={'bold ' + styles.phoneNumberInputLabel}
          >
            Fortsett med telefonnummer
          </label>
        )}
        {usingPhoneNumber && (
          <div className={styles.smsInfo}>
            <p className={'bold'}>Engangskode på SMS</p>
            <div className={'center row'}>
              <p>
                {selectedCountry.countryCallingCode}&nbsp;{usingPhoneNumber}
                &nbsp; (Feil nr?&nbsp;
              </p>
              <p className={styles.link} onClick={() => resetSMSAuth()}>
                Klikk her
              </p>
              <p>)</p>
            </div>
          </div>
        )}
        <div className={styles.phoneNumberInputWrapper}>
          {!confirmationResult && (
            <CountryPicker
              onCountrySelect={(country) => setSelectedCountry(country)}
            />
          )}
          <input
            id={'phone-number-input'}
            className={styles.phoneNumberInput}
            type={'tel'}
            value={smsInput}
            placeholder={confirmationResult ? 'Kode' : 'Ditt nummer'}
            onChange={(e) => {
              setSMSInput(e.target.value.replace(/\s/g, ''));
            }}
            onKeyPress={(e) => {
              if (isNaN(e.key) || e.key === ' ') e.preventDefault();
              if (e.key === 'Enter') {
                confirmationResult ? confirmSMS() : sendSMS();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              setSMSInput(e.clipboardData.getData('Text').replace(/\s/g, ''));
            }}
          />
        </div>
        {inputErrorMessage && (
          <div className={styles.inputErrorMessageWrapper}>
            <p className={styles.inputErrorMessage}>{inputErrorMessage}</p>
          </div>
        )}
        <Button
          id={'send-code'}
          className={styles.smsButton}
          onClick={confirmationResult ? confirmSMS : sendSMS}
        >
          {confirmationResult ? 'Bekreft' : 'Send meg kode på SMS'}
        </Button>
        {secondsBeforeResendSMS != null && secondsBeforeResendSMS > -1 && (
          <div className={styles.resendSMSWrapper}>
            <p>Be om ny kode om {secondsBeforeResendSMS} sekunder</p>
          </div>
        )}
        {usingPhoneNumber && secondsBeforeResendSMS < 0 && (
          <Button
            id={'send-code'}
            className={styles.resendSMSButton}
            onClick={sendSMS}
          >
            Send ny kode
          </Button>
        )}
        <div id={'recaptcha-container'} />
      </div>
    </div>
  );
};

export default Login;
