import axiosClient from "services";

export default class VippsService {
  static getRedirectURI(state) {
    let url = "/vipps/auth";
    if (state) url += `?state=${state}`;
    return axiosClient.get(url);
  }

  static getToken(code, state) {
    return axiosClient.get("/vipps/token", {
      params: { code, state },
    });
  }

  static getUser(token) {
    return axiosClient
      .get("/vipps/user", {
        params: { token },
      })
      .then((userRes) => {
        const user = userRes.data;
        return {
          address: user.address,
          birthdate: user.birthdate,
          email: user.email,
          emailVerified: user.email_verified,
          name: user.name,
          firstName: user.given_name,
          lastName: user.family_name,
          phoneNumber: user.phone_number,
        };
      });
  }
}
