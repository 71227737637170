import React from "react";
import styles from "./Loading.module.css";
import Lottie from 'react-lottie';

// Assets
import * as loadingIcon from 'assets/lottie/loading.json';

const Loading = () => {
  const loadingIconOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return <div className={styles.loading}>
    <Lottie 
	    options={loadingIconOptions}
        height={200}
        width={200}
      />
  </div>;
};

export default Loading;
