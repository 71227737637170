import React, { useState, createContext } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Components
import TopBanner from 'components/layout/TopBanner/TopBanner.js';
import Loading from 'components/base/Loading/Loading.js';

// Views
import Login from 'views/login/login';
import NotFound from 'views/notFound/notFound';
import Vipps from 'views/vipps/vipps';

// Utils
import { generateUUID } from 'utils/uuid';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

export const AppContext = createContext();

export default function App() {
  const [app, setApp] = useState({
    isLoading: false,
  });

  // Redirect forskning.motkraft.no
  if (window.location.hostname.split('.')[0] === 'forskning') {
    setApp({ ...app, isLoading: true });
    window.location.href =
      'https://docs.google.com/forms/d/e/1FAIpQLSdE6WndZrKr7NpnaXp1SHGRkLwiWUvpM-fl3JMT9OTcuNZEeQ/viewform?usp=sf_link';
    return <></>;
  }

  let anonymousId = sessionStorage.getItem('anonymous_id');
  if (!anonymousId) {
    anonymousId = generateUUID();
    sessionStorage.setItem('anonymous_id', anonymousId);
  }

  Sentry.init({
    dsn: 'https://828152bf5dc24bf0bb84bf865cb43e71@o1376888.ingest.sentry.io/6705499',
    //integrations: [new BrowserTracing()],
    integrations: [
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  return (
    <AppContext.Provider value={{ app, setApp }}>
      {app.isLoading && (
        <div className={'loading-cover'}>
          <Loading />
        </div>
      )}
      <BrowserRouter>
        <TopBanner />
        <Routes>
          <Route path={'/login'} element={<Login />} />
          <Route path={'/vipps'} element={<Vipps />} />
          <Route path={'*'} element={<NotFound />} />
          <Route path={'/'} element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

render(<App />, document.getElementById('root'));
