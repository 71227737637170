// Services
import { firestore } from './firebaseService';
import { addDoc, collection } from 'firebase/firestore';

export const analyticEventTypes = {
  INITIALIZE: 'INITIALIZE',
  LOGIN_ATTEMPT: 'LOGIN_ATTEMPT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  ERROR: 'ERROR',
};

export default class AnalyticsService {
  static sendEvent(eventType, payload) {
    let event = {
      type: eventType,
      clientId: sessionStorage.getItem('client_id') || '',
      userId: sessionStorage.getItem('anonymous_id') || '',
      timestamp: new Date(),
      payload: payload || {},
    };

    return addDoc(collection(firestore, 'events'), event);
  }
}
