import axiosClient from 'services';

export default class oAuthService {
  static authorize(
    user,
    client_id,
    redirect_uri,
    state,
    code_challenge,
    code_challenge_method
  ) {
    let data = {
      user,
      client_id,
      state,
      redirect_uri: 'https://id.motkraft.no/api' + '/oauth/login',
      //redirect_uri: "http://localhost:5001/dev-motkraft-id-server/us-central1/api/api" + "/oauth/login",
      client_redirect_uri: redirect_uri,
      grant_type: 'authorization_code',
      response_type: 'code',
      code_challenge,
      code_challenge_method,
    };

    return axiosClient.post('/oauth/authorize', data);
  }
}
