import React, { useState, useRef, useEffect } from "react";
import styles from "./CountryPicker.module.css";

// Hooks
import { useClickOutside } from "hooks/useClickOutside";

// Assets
import Countries from "assets/constants/countries";

const countries = Countries.filter((c) => c.countryCallingCode).sort((a, b) =>
  a.name.localeCompare(b.name)
);

const defaultCountry = {
  flags: {
    svg: "https://flagcdn.com/no.svg",
  },
  countryCallingCode: "+47",
};

const CountryPicker = ({ onCountrySelect = () => {} }) => {
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => onCountrySelect(selectedCountry), [selectedCountry]);

  useClickOutside(dropdownRef, () => {
    setShowDropdown(false);
  });

  const toggleDropdown = (show) => {
    if (show) return setShowDropdown(show);
    setShowDropdown(!showDropdown);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
  };

  return (
    <div className={styles.container}>
      {selectedCountry && (
        <div
          className={styles.dropDownItem + " " + styles.selectedItem}
          onClick={() => toggleDropdown(true)}
        >
          <img src={selectedCountry.flags.svg} />
          <span className={styles.callingCode}>
            {selectedCountry.countryCallingCode}
          </span>
        </div>
      )}
      {showDropdown && (
        <div ref={dropdownRef} className={styles.dropDown}>
          {countries.map((country, i) => (
            <div
              key={i}
              className={
                styles.dropDownItem +
                " " +
                (i !== countries.length - 1 ? styles.borderBottom : "")
              }
              onClick={() => handleCountrySelect(country)}
            >
              <img src={country.flags.svg} />
              <span className={styles.callingCode}>
                {country.countryCallingCode}
              </span>
              <span className={styles.name}>{country.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountryPicker;
