import React from "react";
import styles from "./TopBanner.module.css";

// Components
import { FaArrowLeft } from "react-icons/fa";

const TopBanner = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className={"center row " + styles.topBanner}>
      <div
        className={"center row pointer " + styles.backButton}
        onClick={goBack}
      >
        <FaArrowLeft className={styles.arrowLeft} />
        <span>Tilbake</span>
      </div>
    </div>
  );
};

export default TopBanner;
